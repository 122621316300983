export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'PieChartIcon',
    action: 'manage',
    resource: 'Dashboard',
  },
  {
    title: 'Interventions',
    route: 'interventions-list',
    icon: 'ToolIcon',
    action: 'manage',
    resource: 'Interventions',
  },
  {  
    title: 'Artisans',
    route: 'artisans',
    icon: 'UserIcon',
    action: 'manage',
    resource: 'Artisans',
  },
  {  
    title: 'Factures',
    route: 'factures',
    icon: 'UserIcon',
    action: 'manage',
    resource: 'Factures',
  },
  {
    title: 'Avis',
    route: 'avis',
    icon: 'MessageSquareIcon',
    action: 'manage',
    resource: 'Avis',
  },
  
  {
    title: 'Paramètres',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Services',
        route: 'services',
        action: 'manage',
        resource: 'Services',
      },
      {
        title: 'Abonnements',
        route: 'abonnements',
        action: 'manage',
        resource: 'Abonnements',
      }
    ]
  }
]
